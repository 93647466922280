import auth, { AuthParams } from '@/utils/authentication';
import React, { useEffect, useMemo, useState } from 'react';
import {
  IconCamera,
  IconDice,
  IconFile,
  IconGift,
  IconHome,
  IconImage,
  IconInfoCircle,
  IconMindMapping,
  IconSettings,
  IconShareExternal,
  IconStamp,
  IconSun,
  IconUser,
  IconWifi
} from '@arco-design/web-react/icon';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  icon?: React.ReactNode;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
};

export const routes: IRoute[] = [
  {
    name: '工作台',
    key: 'dashboard/workplace',
    icon: <IconHome />
  },
  {
    name: '田园管理',
    icon: <IconDice />,
    key: 'farmland',
    children: [
      {
        name: '土地管理',
        icon: <IconDice />,
        key: 'farmland/index',
        children: [
          {
            name: '地块管理',
            key: 'farmland/plot/index',
            ignore: true
          },
          {
            name: '智能设备管理',
            key: 'farmland/plot/iotDevice',
            ignore: true
          },
          {
            name: '租赁价格管理',
            key: 'farmland/price',
            ignore: true
          }
        ]
      },
      {
        name: '作物管理',
        icon: <IconSun />,
        key: 'crops'
      },
      {
        name: '租赁周期',
        icon: <IconMindMapping />,
        key: 'cycle/index',
        children: [
          {
            name: '租赁周期托管价格',
            icon: <IconMindMapping />,
            key: 'cycle/cycleHostingPrice',
            ignore: true
          }
        ]
      },
      // {
      //   name: '托管管理',
      //   icon: <IconImport />,
      //   key: 'hosting'
      // },
      {
        name: '服务管理',
        icon: <IconStamp />,
        key: 'service'
      },
      {
        name: '收成管理',
        key: 'farmland/harvest/index',
        icon: <IconFile />
      }
    ]
  },
  {
    name: '订单管理',
    icon: <IconFile />,
    key: 'order',
    children: [
      {
        name: '租赁订单',
        key: 'order/lease/index',
        children: [
          {
            name: '租赁订单列表',
            key: 'order/lease/info',
            ignore: true
          }
        ]
      },
      {
        name: '服务订单',
        key: 'order/service'
      }
    ]
  },

  {
    name: '活动管理',
    key: 'activities',
    icon: <IconGift />,
    children: [
      {
        name: '活动列表',
        key: 'activities/index'
      },
      {
        name: '活动报名订单',
        key: 'activities/order'
      }
    ]
  },
  {
    name: '设备管理',
    key: 'iotModule',
    icon: <IconWifi />,
    children: [
      {
        name: '监控设备管理',
        icon: <IconCamera />,
        key: 'iotModule/camera'
      },
      {
        name: '查看监控',
        icon: <IconSun />,
        key: 'iotModule/cameraShow',
        ignore: true
      }
    ]
  },
  {
    name: '田园动态',
    key: 'moments',
    icon: <IconShareExternal />
  },
  {
    name: '田园公告',
    key: 'news',
    icon: <IconInfoCircle />
  },
  {
    name: '用户管理',
    key: 'mini',
    icon: <IconUser />
  },
  {
    name: '轮播图管理',
    key: 'banner',
    icon: <IconImage />
  },
  {
    name: '系统配置',
    key: 'config',
    icon: <IconSettings />
  }
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      return first?.children?.[0]?.key || first.key;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
