import axios, { AxiosResponse } from 'axios';

const ACCESS_TOKEN = 'token';
const api_url = {
  'product': 'https://farmland.funengyun.cn/api',
  'dev': 'https://farmland.funengyun.cn/api'
};

//接口地址
const baseURL = process.env.NODE_ENV === 'development' ? api_url.dev : api_url.product;
const uploadUrl = `${baseURL}/admin/upload`;
//静态资源url
const request = axios.create({
  baseURL,
  headers: {
    'accept': 'application/json'
  },
  timeout: 60000
});

const token = localStorage.getItem(ACCESS_TOKEN);
request.interceptors.request.use((config) => {
  token && (config.headers[ACCESS_TOKEN] = token);
  return config;
});

interface Res extends AxiosResponse {
  code: number;
  msg: string;
  data: any;
}

request.interceptors.response.use((response: Res) => {

  if (response.data.code === -9999) {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }
  return response.data as Res;
});
export { request, baseURL, uploadUrl, token };
export type { Res };
