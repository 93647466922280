import React from 'react';
import LoginForm from './form';
import styles from './style/index.module.less';

function Login() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoginForm />
      </div>
    </div>
  );
}

Login.displayName = 'LoginPage';

export default Login;
