import './style/global.less';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from '@arco-design/web-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageLayout from './layout';
import { GlobalContext } from './context';
import Login from './pages/login';
import checkLogin from './utils/checkLogin';
import changeTheme from './utils/changeTheme';
import useStorage from './utils/useStorage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import rootReducer from '@/store';
import { createStore } from 'redux';
import { Res } from '@/utils/request';

const queryClient = new QueryClient();
const store = createStore(rootReducer);

function Index() {
  const [lang, setLang] = useStorage('arco-lang', 'zh-CN');
  const [theme, setTheme] = useStorage('arco-theme', 'light');

  useEffect(() => {
    checkLogin().then((res: Res) => {
      if (res.code == 0) {
      } else {
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }
    });
  }, []);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme
  };

  return (
    <BrowserRouter>
      <ConfigProvider
        componentConfig={{
          Card: {
            bordered: false
          },
          List: {
            bordered: false
          },
          Table: {
            border: false
          }
        }}
      >
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <GlobalContext.Provider value={contextValue}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={PageLayout} />
              </Switch>
            </GlobalContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

// ReactDOM.render(<Index />, document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render(<Index />);