import React, { useContext } from 'react';
import { Button, Dropdown, Menu, Tooltip } from '@arco-design/web-react';
import { IconMoonFill, IconPoweroff, IconSettings, IconSunFill } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import { request } from '@/utils/request';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const { theme, setTheme } = useContext(GlobalContext);

  function logout() {
    request.get('/admin/logout').then(res => {
      localStorage.clear();
      window.location.href = '/login';
    });
  }

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    }
  }

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <div className={styles['logo-name']}>共享田园</div>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position="br">
              <Button type={'default'} size={'mini'}>{userInfo.username}</Button>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
